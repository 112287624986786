import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { StatefulLayout } from 'cuemate-charts';

import sessionOverviewConfig from './configurations/sessionOverviewConfig';
import sessionReportsConfig from './configurations/sessionReportsConfig';
import playerSummaryConfig from './configurations/playerSummaryConfig';
import classReportsConfig from './configurations/classReportsConfig';
import TrainingContent from './configurations/trainingReportsConfig';

import ClassDiagnostics from './components/ClassDiagnostics';
import CVClassReports from './components/CVClassReports';
import SocialTab from '../../social/SocialTab';

function SessionOverview(props) {
  const reportType = useSelector((state) => state.reportType);
  const config = useSelector((state) => state.config);
  const sessionData = useSelector((state) => state.sessionData);

  const data = {
    reportType,
    config,
    sessionData,
  };
  return <StatefulLayout viewConfig={sessionOverviewConfig} data={{ ...data, ...props }} />;
}

function SessionReports(props) {
  const [filteredSets, setFilters] = useState([]);
  const activeContent = useSelector((state) => state.activeContent);
  const reportType = useSelector((state) => state.reportType);
  const config = useSelector((state) => state.config);
  const elements = useSelector((state) => state.elements);
  const sessionData = useSelector((state) => state.sessionData);
  const units = useSelector((state) => state.units);
  const username = useSelector((state) => state.username);
  const cmvThresholds = useSelector((state) => state.cmvThresholds);

  const onTransition = (d) => {
    const { onTransition: onTransitionProps } = props;
    onTransitionProps(d);
    setFilters([]);
  };

  const data = {
    activeContent,
    reportType,
    config,
    elements,
    sessionData,
    units,
    username,
    cmvThresholds,
  };
  return (
    <StatefulLayout
      viewConfig={sessionReportsConfig}
      data={{ ...data, ...props, onTransition, filteredSets, setFilters }}
    />
  );
}

SessionReports.propTypes = {
  onTransition: PropTypes.func,
};

function ClassReports(props) {
  const activeContent = useSelector((state) => state.activeContent);
  const activityData = useSelector((state) => state.activityData);
  const reportType = useSelector((state) => state.reportType);
  const config = useSelector((state) => state.config);
  const elements = useSelector((state) => state.elements);
  const classData = useSelector((state) => state.classData);
  const refRanges = useSelector((state) => state.refRanges);
  const units = useSelector((state) => state.units);

  const data = {
    activeContent,
    activityData,
    reportType,
    config,
    elements,
    classData,
    refRanges,
    units,
  };
  return (
    <StatefulLayout
      viewConfig={classReportsConfig}
      stateConfig={['refId']}
      data={{ ...data, ...props }}
    />
  );
}

function PlayerSummary(props) {
  const reportType = useSelector((state) => state.reportType);
  const elements = useSelector((state) => state.elements);
  const sessionData = useSelector((state) => state.sessionData);
  const classData = useSelector((state) => state.classData);
  const refRanges = useSelector((state) => state.refRanges);
  const classStats = useSelector((state) => state.classStats);
  const activityData = useSelector((state) => state.activityData);
  const units = useSelector((state) => state.units);
  const rating = useSelector((state) => state.rating);

  const data = {
    reportType,
    elements,
    sessionData,
    classData,
    refRanges,
    classStats,
    activityData,
    units,
    rating,
  };
  return (
    <StatefulLayout
      viewConfig={playerSummaryConfig}
      stateConfig={['refId']}
      data={{ ...data, ...props }}
    />
  );
}

function TrainingReports(props) {
  const username = useSelector((state) => state.username);
  const activeContent = useSelector((state) => state.activeContent);
  const elements = useSelector((state) => state.elements);
  const classData = useSelector((state) => state.classData);
  const reportType = useSelector((state) => state.reportType);
  const units = useSelector((state) => state.units);
  const rating = useSelector((state) => state.rating);

  const data = {
    username,
    activeContent,
    elements,
    classData,
    reportType,
    units,
    rating,
  };
  return <TrainingContent {...data} {...props} />;
}

export default (activity = 'tennis') => {
  const generalParameters = {};

  const activityParameters = {
    tennis: {
      types: ['groundstroke', 'serve', 'volley'],
      metrics: [
        {
          name: 'pace',
          units: ['m/s', 'km/h', 'mph', 'knot', 'ft/s'],
          scales: [1, 3.6, 2.236936, 1.943844, 3.28084],
        },
        {
          name: 'spin',
          units: ['rpm', 'rps', 'rad/s', 'deg/s'],
          scales: [1, 1 / 60, Math.PI / 30, 6],
        },
      ],
      classAttributes: [
        {
          key: 'impactSuccess',
          label: 'Sweet Spot[%]',
          scale: 100.0,
          format: '.0f',
        },
        {
          key: 'score',
          label: 'Str. Qual.',
          scale: 10.0,
          format: '.1f',
        },
        {
          key: 'pace',
          label: 'Est. Ball Spd.[m/s]',
          scale: 1.0,
          leftLine: true,
          format: '.0f',
        },
        {
          key: 'spin',
          label: 'Spin[rad/s]',
          scale: 1.0,
        },
      ],
      developmentTrend: {
        default: [
          { name: 'cumCount', label: 'Cumulative Count' },
          { name: 'completeness', label: 'Repertoire Completeness [%]' },
        ],
        dataDriven: [{ name: 'cumCount', label: 'Cumulative Count' }],
      },
    },
    ski: {
      classAttributes: [
        { key: 'score', label: 'Score (%)', scale: 100.0 },
        { key: 'outcome', label: 'Outcome (%)', scale: 100.0, leftLine: true },
        { key: 'condition', label: 'Condition (%)', scale: 100.0 },
        { key: 'technique', label: 'Technique (%)', scale: 100.0 },
        { key: 'performance', label: 'Performance (%)', scale: 100.0 },
      ],
    },
  };

  return {
    reportTabs: [
      { name: 'HOME', component: SessionOverview },
      { name: 'SESSION_REPORTS', component: SessionReports },
      { name: 'PLAYER_REPORT', component: PlayerSummary },
      { name: 'CLASS_REPORTS', component: ClassReports },
      { name: 'CLASS_DIAGNOSTICS', component: ClassDiagnostics, experiment: true },
      { name: 'TRAINING', component: TrainingReports },
      { name: 'CV_REPORTS', component: CVClassReports },
      // { name: 'LEADERBOARD', component: Leaderboard }, moving this to social
      { name: 'SOCIAL', component: SocialTab },
    ],

    getTransitionID: (context) => {
      if (context.includes('20')) {
        // Session date
        return `SESSION_REPORTS,${context}`;
      }
      if (context.includes(' ')) {
        // Class name
        // eslint-disable-next-line no-nested-ternary
        return context.startsWith('_')
          ? `CLASS_DIAGNOSTICS,${context.slice(1)}`
          : context.startsWith('/')
          ? `CV_REPORTS,${context.slice(1)}`
          : `CLASS_REPORTS,${context}`;
      }
      if (['overview', 'summary'].indexOf(context.toLowerCase()) >= 0) {
        return 'PLAYER_REPORT';
      }
      return context.toUpperCase();
    },

    parameters: {
      ...generalParameters,
      ...activityParameters[activity],
    },
  };
};
