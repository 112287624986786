import { API, Auth } from 'aws-amplify';
/* Report Components */
import {
  DataToggler,
  NoReportMessage,
  tennisUtilities,
  UnitToggler,
  LoadingState,
} from 'cuemate-charts';
/* Styles */
import 'cuemate-charts/build/main.css';
import * as d3 from 'd3';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Progress, Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as actions from '../../../../actions';
import ReportFramework from './components/ReportFramwork';
import reportConfig from './reportConfig';
import { DATA_TYPE, getUserReport, listUserReports, getPublicReport } from './util/dataQuery';
import processLeaderboardData from './util/utils';
import { cuemateGET, cuematePOST, useAsync } from '../../../../libs/dataAccess';
import useCheckMobileScreen from '../../../../libs/mobileUtilities';
import SocialTab from '../../social/SocialTab';

export const ActivityType = {
  TENNIS: 'tennis',
  PICKLEBALL: 'pickleball',
};

/* Processing Functions  */
const { processElementData, processSessionData, processClassData, processActivityData, unitPairs } =
  tennisUtilities;

async function getLeaderboardForGroup(group) {
  if (group === 'global') {
    const currentSession = await Auth.currentSession();
    const leaderboardData = await cuemateGET(
      '/users/social/leaderboard',
      currentSession.accessToken.jwtToken
    );

    return processLeaderboardData(leaderboardData.data);
  }
  return API.get('cuemate-tennis', `/leaderboard/${group}`);
}

async function getClassStats(user) {
  const res = await getPublicReport('class_stats_users_with_group.v2.csv');
  if (res) {
    return res.map((item) => {
      const updatedItem = { ...item, user: item.user === user ? user : null };
      d3.keys(item).forEach((key) => {
        if (key.includes('spin')) {
          updatedItem[key] = item[key] * (Math.PI / 30);
        }
      });
      return updatedItem;
    });
  }
  return [];
}

async function getCmvThresholds(user) {
  const res = await getPublicReport('cmv_users_thrshld.csv');
  const userThreshold = [];
  if (res) {
    res
      .filter((d) => d.user.includes(user))
      .forEach((e) => {
        userThreshold.splice(userThreshold.length, 0, {
          threshold: [+e.cmv_baseline, +e.cmv_thrshld2],
        });
      });
  }
  if (userThreshold.length === 0) {
    userThreshold.splice(0, 0, { threshold: [2.1076, 2.385] });
  }
  return userThreshold;
}

function CustomedLoadingState({ loadingText = 'Loading...' }) {
  return (
    <Container className="LoagindContent align-items-center justify-content-center">
      <Row>
        <Col className="text-center">
          <i className="fa fa-spinner fa-pulse fa-4x fa-fw" aria-hidden="true" />
          <span className="sr-only">Loading...</span>
          <p className="mt-5 text-center animated flash">{loadingText}</p>
        </Col>
      </Row>
    </Container>
  );
}

CustomedLoadingState.propTypes = {
  loadingText: PropTypes.string,
};

const getUserInfo = async () => {
  const currentUser = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
  const userGroups = ['global'];
  const cognitoGroups = currentUser.signInUserSession.accessToken.payload['cognito:groups'];
  if (cognitoGroups) {
    cognitoGroups
      .filter((d) => d.startsWith('CUEMATE'))
      .forEach((d) => {
        userGroups.push(d);
      });
  }
  return { username: currentUser.username, userGroups };
};

export default function TennisReport({ reportType }) {
  const [isLoading, error, currentUser] = useAsync(getUserInfo, []);
  if (error) return NoReportMessage;
  return isLoading ? (
    <CustomedLoadingState loadingText="Loading User Info" />
  ) : (
    <UserTennisReport {...{ currentUser, reportType: reportType || 'basic' }} />
  );
}

TennisReport.propTypes = {
  reportType: PropTypes.string,
};

const listSessions = async () => {
  const currentSession = await Auth.currentSession();
  // Call to the sessions api for tennis sessions
  const tennisResponse = await cuemateGET(
    '/session?type=tennis&limit=1000',
    currentSession.accessToken.jwtToken
  );

  // Call to the sessions api for tennis sessions
  const pickleballResponse = await cuemateGET(
    '/session?type=pickleball&limit=1000',
    currentSession.accessToken.jwtToken
  );
  // Combine tennis & pickleball api calls. Check if either response is missing the data field
  const response = [
    ...(tennisResponse && 'data' in tennisResponse ? tennisResponse.data : []),
    ...(pickleballResponse && 'data' in pickleballResponse ? pickleballResponse.data : []),
  ];
  // formating the data to what the caller is expecting
  return {
    cMTennisSessions: !response
      ? []
      : response.map((session) => ({
          _id: session.id,
          startTS: session.startTS,
          type: session.type,
          _numberStrokes: session.numberMotionUnits,
          partner: session.partner,
          partnerName: session.partnerName,
          location: session.location ? session.location : '',
          sets: session.sets.map((s) => ({
            startTS: s.startTS,
            typeRaw: s.typeRaw,
            opponent: s.opponent,
            tags: s.tags,
            numberOfStrokes: s.numberMotionUnits,
            duration: s.duration ? Math.abs(s.duration) : 0,
            subTypeRaw: s.subTypeRaw,
            playModalityRaw: s.playModalityRaw,
            _effortLevel: s.effortLevel,
            note: s.note,
          })),
        })),
  };
};

const getUserSummaryProfiles = async () => {
  const currentSession = await Auth.currentSession();
  const response = await cuemateGET(
    '/classprofile/summary?type=tennis',
    currentSession.accessToken.jwtToken
  );
  // formating the data to what the caller is expecting
  return !response
    ? []
    : response.map((profile) => {
        const updatedProfile = { classification: profile.classification };
        profile.metrics.forEach((metric) => {
          updatedProfile[metric.name] = metric.value;
        });
        profile.trends.forEach((metric) => {
          updatedProfile[metric.name] = metric.value;
        });
        return updatedProfile;
      });
};

const getUserGroups = async () => {
  const currentSession = await Auth.currentSession();
  const response = await cuemateGET(
    '/student/groups?type=tennis',
    currentSession.accessToken.jwtToken
  );
  // formating the data to what the caller is expecting
  return response;
};

const getGroupRefRanges = async (group) => {
  const currentSession = await Auth.currentSession();
  if ('name' in group) {
    // eslint-disable-next-line no-param-reassign
    group.description = group.name;
  }
  const response = await cuematePOST(
    '/referenceranges?type=tennis',
    currentSession.accessToken.jwtToken,
    group
  );
  // formating the data to what the caller is expecting
  return response.map((d) => {
    const strokeProfile = {};
    d.trends.forEach((metric) => {
      const { name, value } = metric;
      const updatedName = name
        .split('_')
        .map((w, i) => (i === 0 ? w : w.charAt(0).toUpperCase() + w.slice(1)))
        .join('');
      strokeProfile[updatedName] = value;
    });
    const res = {
      group: `[${d.type}] ${d.name}`,
      classification: d.classification,
      useFrequency: d.useFrequency,
      strokeProfile,
    };
    d.metrics.forEach((metric) => {
      const { name, mean, std, ranges } = metric;
      res[name] = { mean, std, ranges };
    });
    return res;
  });
};

const getUserActivity = async () =>
  Promise.all([listUserReports('elements/'), listUserReports('session-elements/', 'vision-data')]);

const getUserProfile = async () => {
  const currentSession = await Auth.currentSession();
  const response = await cuemateGET('/userprofile', currentSession.accessToken.jwtToken);
  return !response
    ? {}
    : (response && response.properties && response.properties.units) || 'imperial';
};

const getUserRating = async () => {
  const currentSession = await Auth.currentSession();
  const response = await cuemateGET('/playerrating?filter=1', currentSession.accessToken.jwtToken);
  if (response.message !== undefined && response.message.includes('not found')) {
    return {};
  }
  const result = !response ? {} : response;
  return result;
};

const getClassProfiles = async (activityData) => {
  // eslint-disable-next-line no-underscore-dangle
  const sessionsIds = Object.values(activityData).map((v) => v._id);
  if (sessionsIds.length === 0) {
    return {};
  }
  const currentSession = await Auth.currentSession();
  const response = await cuematePOST(
    '/classprofile/sessions?filter=3',
    currentSession.accessToken.jwtToken,
    { sessionsIds }
  );
  // formating the data to what the caller is expecting
  return Object.assign({}, ...response.map((r) => ({ [r.id]: r.data })));
};

const getInitialData = async () => {
  const response = await Promise.all([
    listSessions(),
    getUserSummaryProfiles(),
    getUserGroups(),
    getUserActivity(),
    getUserRating(),
    getUserProfile(),
  ]);
  const activityResponse = response[0];
  const summaryResponse = response[1];
  const userGroups =
    response[2].length === 0 ? [{ user: 'masterCoach', description: 'All Users' }] : response[2];
  const elementResponses = response[3];
  const ratingResponse = response[4];
  const unitResponse = response[5];
  return {
    activityResponse,
    summaryResponse,
    userGroups,
    elementResponses,
    ratingResponse,
    unitResponse,
  };
};

const UNIT_SYSTEM = ['metric', 'imperial'];

export function UserTennisReport({ currentUser, reportType }) {
  const [isLoading, loadingError, response] = useAsync(getInitialData, []);
  const [stage, setStage] = useState(0);
  const [activityData, setActivityData] = useState({});
  const [updatedSets, setUpdatedSets] = useState([]);
  const [shouldShowSocialTab, setShouldShowSocialTab] = useState(false);
  const [shouldShowLoading, setShouldShowLoading] = useState(true);
  const dispatch = useDispatch();

  const handleStart = () => {}; // setStage(0);
  const handleSubmit = (sets) => {
    setShouldShowLoading(false);
    setUpdatedSets(sets);
    setStage(1);
  };

  useEffect(() => {
    async function getActivityData() {
      if (!isLoading && response) {
        try {
          const { activityResponse, userGroups, elementResponses, ratingResponse, unitResponse } =
            response;
          const updatedActivityData = await processActivityData(activityResponse);
          // Update class profile data
          const ratingHistory = {};
          if (ratingResponse.history !== undefined) {
            ratingResponse.history.forEach((d) => {
              const ratings = {};
              Object.keys(d).forEach((key) => {
                if (!['startTS', 'id'].includes(key)) {
                  ratings[key] = d[key];
                }
              });
              ratingHistory[d.id] = ratings;
            });
          }
          const classProfiles = await getClassProfiles(updatedActivityData);
          const elementList = elementResponses[0].map((d) => d.split('.')[0]);
          const cvDates = elementResponses[1].map((d) => d.split('.')[0]);
          d3.keys(updatedActivityData).forEach((key) => {
            const { date } = updatedActivityData[key];
            // Exclude sessions without element data
            if (elementList.indexOf(date) < 0 && elementList.indexOf(`pickleball-${date}`) < 0) {
              delete updatedActivityData[key];
            } else {
              // Add class profiles
              // eslint-disable-next-line no-underscore-dangle
              updatedActivityData[key].classProfiles =
                // eslint-disable-next-line no-underscore-dangle
                classProfiles[updatedActivityData[key]._id] || [];
              // Add rating
              // eslint-disable-next-line no-underscore-dangle
              updatedActivityData[key].ratings = ratingHistory[updatedActivityData[key]._id] || {};
              // Update computer vision info
              updatedActivityData[key].hasCVElementData = cvDates.includes(date);
            }
          });
          const unitID = UNIT_SYSTEM.indexOf(unitResponse);
          const selectedUnits = unitPairs.map((p) => p.units[unitID]);
          dispatch(actions.addUsername(currentUser.username));
          dispatch(actions.addUserGroups(userGroups));
          dispatch(actions.addActivityData(updatedActivityData));
          dispatch(actions.addRating(ratingResponse));
          dispatch(actions.updateUnits(selectedUnits));
          setActivityData(updatedActivityData);
          const allDates = d3.keys(updatedActivityData);
          const allSessions = d3.values(updatedActivityData);
          const sortedDates = [...allDates].sort((a, b) => (a > b ? 1 : -1));
          const startDate = sortedDates[Math.max(0, sortedDates.length - 20)];
          const selectedSessions = allSessions.filter((_, i) => allDates[i] >= startDate);
          const selectedSets = selectedSessions.map((d) => d.sets).flat();
          if (Object.keys(updatedActivityData).length === 0 || selectedSets.length === 0) {
            setShouldShowSocialTab(true);
            setShouldShowLoading(false);
            setStage(10);
          } else {
            handleSubmit(selectedSets);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
    getActivityData();
  }, [isLoading, currentUser, dispatch]);

  const isMobile = useCheckMobileScreen();
  if (loadingError) console.error(loadingError);

  return (
    <>
      <Container fluid={isMobile}>
        <h2>Hi @{currentUser.username.toUpperCase()}</h2>
        {/* TODO: Add module for unit selection */}
        {!isLoading && updatedSets.length > 0 && (
          <>
            <LoadingReport
              {...{ username: currentUser.username, updatedSets, reportType, stage }}
            />
            <ReportFramework stage={stage} />
          </>
        )}
        {shouldShowSocialTab ? (
          <Container>
            <Row>
              <Col
                md={{
                  offset: 2,
                  size: 8,
                }}
                sm="12"
              >
                <h3 className="padding-top-25">You have not played any sessions yet!</h3>
                <p>
                  In the meantime, go to <Link to="/cuemate101">CueMate 101</Link> or check out some
                  sessions from other players
                </p>
                <hr className="colorgraph" />
              </Col>
            </Row>
            <Row>
              <Col
                md={{
                  offset: 2,
                  size: 8,
                }}
                sm="12"
              >
                <SocialTab />
              </Col>
            </Row>
          </Container>
        ) : (
          shouldShowLoading && <LoadingState />
        )}
      </Container>
      {activityData && updatedSets.length > 0 && (
        <DataToggler
          reportType={reportType}
          activityData={activityData}
          onStart={handleStart}
          onSubmit={handleSubmit}
          open={false}
        />
      )}
      {updatedSets.length > 0 && <UnitSystem />}
    </>
  );
}

function UnitSystem() {
  const units = useSelector((state) => state.units);
  const dispatch = useDispatch();
  const onSubmit = (selections) => {
    dispatch(actions.updateUnits(selections));
  };
  return <UnitToggler units={units} onSubmit={onSubmit} />;
}

UserTennisReport.propTypes = {
  currentUser: PropTypes.object,
  reportType: PropTypes.string,
};

function LoadingReport({ username, updatedSets, reportType, stage }) {
  // Load data from redux
  const userGroups = useSelector((state) => state.userGroups);
  const activityData = useSelector((state) => state.activityData);
  const activeSets = useSelector((state) => state.activeSets);
  const mostRecentSession = Object.values(activityData).slice(-1)[0];
  let activityType = ActivityType.TENNIS;
  if (mostRecentSession.type) {
    activityType = mostRecentSession.type;
  }
  const dispatch = useDispatch();

  // Get promises
  const setStrings = updatedSets.map((d) => d.datetime);
  const setStringsUTC = updatedSets.map((d) => d.datetimeUTC);
  const dates = d3.map(updatedSets, (d) => d.date.slice(0, 10)).keys();
  const cvDates = [];
  d3.values(activityData).forEach((v) => {
    if (dates.includes(v.date) && !cvDates.includes(v.date) && v.hasCVElementData === true) {
      cvDates.push(v.date);
    }
  });
  const dataPromises = [
    {
      name: 'elements',
      // set the file name according to the activity type
      promises: dates.map((d) => {
        let filename;
        if (activityType === ActivityType.TENNIS) {
          filename = `elements/${d}.json`;
        } else {
          filename = `elements/pickleball-${d}.json`;
        }
        return getUserReport(filename, DATA_TYPE.JSON);
      }),
      postProcessing: (d) =>
        d
          .filter((e) => e !== undefined)
          .flat()
          .filter((e) => setStrings.includes(e.set) || setStringsUTC.includes(e.set))
          .map((e) => {
            const index = setStrings.includes(e.set)
              ? setStrings.indexOf(e.set)
              : setStringsUTC.indexOf(e.set);
            const date = `${updatedSets[index].date}T00:00:00.000Z`;
            return { ...e, date };
          }),
    },
    {
      name: 'leaderboards',
      promises: ['global'].map((g) => getLeaderboardForGroup(g)),
      postProcessing: (d) => ['global'].map((g, i) => ({ group: g, ...d[i] })),
    },
    {
      name: 'refRanges',
      promises: userGroups.map(getGroupRefRanges),
      postProcessing: (d) =>
        userGroups
          .map((g, i) => ({
            key: `${g.user}-${g.name}`,
            value: d[i],
          }))
          .filter((item) => item.value.length > 0 && !item.key.includes('ICM')),
    },
    {
      name: 'levelRefRanges',
      promises: ['Beginner', 'Intermediate', 'Advanced', 'Expert'].map((d) =>
        getGroupRefRanges({ description: d, user: 'masterCoach' })
      ),
      postProcessing: (d) =>
        ['Beginner', 'Intermediate', 'Advanced', 'Expert'].map((g, i) => ({
          key: `[Level]-${g}`,
          value: d[i],
        })),
    },
    {
      name: 'rankingRefRanges',
      promises: ['low', 'medium', 'high'].map((d) =>
        getGroupRefRanges({ description: d, user: 'outcomeRating' })
      ),
      postProcessing: (d) =>
        ['Low', 'Medium', 'High'].map((g, i) => ({
          key: `[Outcome]-${g}`,
          value: d[i],
        })),
    },
    {
      name: 'playerRatingRefRanges',
      promises: ['Beginner', 'Intermediate', 'Advanced', 'Expert'].map((d) =>
        getGroupRefRanges({ description: d, user: 'playerRating' })
      ),
      postProcessing: (d) =>
        ['Beginner', 'Intermediate', 'Advanced', 'Expert'].map((g, i) => ({
          key: `[playerRating]-${g}`,
          value: d[i],
        })),
    },
    {
      name: 'typeRefRanges',
      promises: [getGroupRefRanges({ user: 'strokeType' })],
      postProcessing: (d) => d[0],
    },
    {
      name: 'classStats',
      promises: [getClassStats(username)],
      postProcessing: (d) => d[0],
    },
    {
      name: 'visionData',
      promises: cvDates.map((d) =>
        getUserReport(`session-elements/${d}.json`, DATA_TYPE.JSON, 'vision-data')
      ),
      postProcessing: (d) => d.filter((e) => e !== undefined).flat(),
      report: ['advanced', 'experiment'],
    },
    {
      name: 'cmvThresholds',
      promises: [getCmvThresholds(username)],
      postProcessing: (d) => d[0],
    },
  ];

  // Create states
  const [loadingProgress, setLoading] = useState(dataPromises.map(() => false));
  const [isProcessing, setProcessing] = useState(true);
  const [data, setData] = useState({ username });
  const [promises, setPromises] = useState([]);

  // Loading Data
  const onLoadFinished = (name, res) => {
    const index = dataPromises.findIndex((d) => d.name === name);
    setData((oldData) => {
      const updatedData = { ...oldData };
      updatedData[name] = res;
      return updatedData;
    });
    setLoading((oldProgress) => oldProgress.map((p, i) => (i === index ? true : p)));
  };

  const onProcessFinished = (loadedData) => {
    if (dispatch) {
      dispatch(actions.addReportType(reportType));
      dispatch(actions.addActiveSets(updatedSets));
      dispatch(actions.addElements(loadedData.elements));
      const allRefRanges = [
        ...(loadedData.refRanges || []),
        ...(loadedData.playerRatingRefRanges || []),
        ...(loadedData.levelRefRanges || []),
        ...(loadedData.rankingRefRanges || []),
        // ...(loadedData.typeRefRanges || []),
      ];
      dispatch(actions.addRefRanges(allRefRanges));
      dispatch(actions.addClassStats(loadedData.classStats));
      dispatch(actions.addSessionData(loadedData.sessionData));
      dispatch(actions.addClassData(loadedData.classData));
      dispatch(actions.addConfig(loadedData.config));
      dispatch(actions.addLeaderboard(loadedData.leaderboards));
      dispatch(actions.addCmvThresholds(loadedData.cmvThresholds));
      if (loadedData.visionData) dispatch(actions.addVisionData(loadedData.visionData));
    }
    setProcessing(false);
  };

  useEffect(() => {
    const isChanged =
      (updatedSets.length > 0 && updatedSets.length !== activeSets.length) ||
      !updatedSets.every((d, i) => d.startTS === activeSets[i].startTS);
    const updatedLoadingProgress = isChanged ? dataPromises.map(() => false) : loadingProgress;
    const newPromises = dataPromises.filter(
      (p, i) =>
        (p.report === undefined || p.report.includes(reportType)) &&
        updatedLoadingProgress[i] === false
    );
    dispatch(actions.addReportType(reportType));
    setPromises(newPromises);
    setLoading(updatedLoadingProgress);
    setProcessing(true);
  }, [reportType, updatedSets]);

  const isLoading =
    d3.sum(promises, (d) => loadingProgress[dataPromises.findIndex((p) => p.name === d.name)]) !==
    promises.length;

  /* TODO: Invoke functions processSessionData and processClassData
  when reportType changes to advanced so that sessionData and classData are recomputed
  using classKey and then dispatched. */
  if (stage > 0 && updatedSets.length > 0 && promises.length > 0 && (isLoading || isProcessing)) {
    return (
      <Modal isOpen={isLoading || isProcessing} className="modal-lg">
        <ModalHeader>
          <div className="d-flex align-items-center">
            <p className="pr-2 my-1">Data Loading and Processing</p>
            <Spinner size="sm" color="primary" />
          </div>
        </ModalHeader>
        <ModalBody>
          {promises.map((d) => (
            <LoadingProgress
              key={d.name}
              name={d.name}
              promises={d.promises}
              onFinished={onLoadFinished}
              postProcessing={d.postProcessing}
            />
          ))}
          {!isLoading && (
            <ProcessingProgress
              data={{ ...data, activityData }}
              reportType={reportType}
              onFinished={onProcessFinished}
            />
          )}
        </ModalBody>
      </Modal>
    );
  }
  return null;
}

LoadingReport.propTypes = {
  username: PropTypes.string,
  updatedSets: PropTypes.array,
  reportType: PropTypes.string,
  stage: PropTypes.number,
};

function LoadingProgress({ name, promises, onFinished, postProcessing }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const getResponse = async () => {
      const step = 100 / promises.length;
      const updatedPromises = promises.map(async (promise) => {
        const result = await promise;
        setProgress((oldProgress) => oldProgress + step);
        return result;
      });
      let response = await Promise.all(updatedPromises);
      if (postProcessing) {
        response = postProcessing(response);
      }
      onFinished(name, response);
    };
    getResponse();
  }, [name]);

  return (
    <div className="w-100 d-flex mt-4">
      <Col
        sm={{
          offset: 1,
          size: 10,
        }}
      >
        <Progress
          animated={progress < 100}
          striped={progress >= 100}
          value={parseInt(progress, 10)}
        />
      </Col>
    </div>
  );
}

LoadingProgress.propTypes = {
  name: PropTypes.string,
  promises: PropTypes.array,
  onFinished: PropTypes.func,
  postProcessing: PropTypes.func,
};

function ProcessingProgress({ reportType, data, onFinished }) {
  const [progress, setProgress] = useState(0);
  const totalSteps = 5;

  useEffect(() => {
    setProgress(0);
    const classKey = ['experiment'].includes(reportType)
      ? 'advancedUtilityClassification'
      : 'classification';
    const currentState = { ...data };
    const mostRecentSession = Object.values(data.activityData).slice(-1)[0];
    let activityType = ActivityType.TENNIS;
    if (mostRecentSession.type) {
      activityType = mostRecentSession.type;
    }
    const filteredElements = currentState.elements
      .filter((d) => !d.classification.includes('serve backhand'))
      // filter out invalid strokes, and outliers for tennis only
      .filter(
        (d) =>
          (!('isValid' in d) || d.isValid) &&
          (!('isOutlier' in d) || !d.isOutlier || activityType === ActivityType.PICKLEBALL)
      );
    currentState.elements = processElementData(
      filteredElements,
      currentState.visionData,
      activityType
    );
    // setProgress(1);
    // currentState.refRanges = processReferenceData(currentState.refRanges);
    // setProgress(2);
    currentState.sessionData = processSessionData(
      currentState.elements,
      currentState.activityData,
      classKey
    );
    // setProgress(3);
    currentState.classData = processClassData(
      currentState.elements,
      currentState.refRanges,
      classKey
    );

    // Process Ranking Reference Ranges
    const classRankingRefs = d3
      .nest()
      .key((d) => d.classification)
      .rollup((d) => d)
      .entries(
        currentState.rankingRefRanges
          .map((g) => g.value.map((d) => ({ group: g.key, ...d })))
          .flat()
      );
    if (classRankingRefs.length > 0) {
      currentState.classData = currentState.classData.map((c) => {
        const result = { ...c };
        const classRef = classRankingRefs.find((d) => d.key === c.name);
        if (classRef) {
          result.refRanges = classRef.value;
          const thres = result.refRanges[0].thresholds.ranges;
          result.level = d3.sum(thres, (t) => c.envelopeEfficiency >= t);
        }
        return result;
      });
    }
    // Process Level Reference Ranges
    const groupLevelRefs = d3
      .nest()
      .key((d) => d.classification)
      .rollup((d) => d)
      .entries(
        currentState.levelRefRanges.map((g) => g.value.map((d) => ({ group: g.key, ...d }))).flat()
      );
    if (groupLevelRefs.length > 0) {
      currentState.classData = currentState.classData.map((c) => {
        const result = { ...c };
        const levelRef = groupLevelRefs.find((d) => d.key === c.name);
        if (levelRef) {
          result.levelRefRanges = levelRef.value;
        }
        return result;
      });
    }
    // Process Stroke type Reference Ranges
    const profileTypeRefs = d3
      .nest()
      .key((d) => d.classification)
      .rollup((d) => d)
      .entries(currentState.typeRefRanges.map((g) => ({ group: g.description, ...g })));
    if (profileTypeRefs.length > 0) {
      currentState.classData = currentState.classData.map((c) => {
        const result = { ...c };
        const typeRef = profileTypeRefs.find((d) => d.key === c.name);
        if (typeRef) {
          result.typeRefRanges = typeRef.value;
        }
        return result;
      });
    }
    // Process playerRating Reference Ranges
    const playerRatingRefRanges = d3
      .nest()
      .key((d) => d.classification)
      .rollup((d) => d)
      .entries(
        currentState.playerRatingRefRanges
          .map((g) => g.value.map((d) => ({ group: g.key, ...d })))
          .flat()
      );
    if (playerRatingRefRanges.length > 0) {
      currentState.classData = currentState.classData.map((c) => {
        const result = { ...c };
        const playerRatingRef = playerRatingRefRanges.find((d) => d.key === c.name);
        if (playerRatingRef) {
          result.playerRatingRefRanges = playerRatingRef.value;
        }
        return result;
      });
    }
    // setProgress(4);
    const config = reportConfig('tennis');
    if (currentState.visionData === undefined || currentState.visionData.length === 0) {
      config.reportTabs = config.reportTabs.filter((d) => !d.name.startsWith('CV'));
    }
    currentState.config = config;
    // Additional Processing
    currentState.sessionData.availableClasses = currentState.classData
      .filter((d) => d.show)
      .map((d) => d.name);
    const { leaderboards, username } = currentState;
    if (!!leaderboards && leaderboards.length > 0) {
      const leaderboard = leaderboards.find((d) => d.group === 'global') || leaderboards[0];
      const { user } = leaderboard;
      if (user) {
        const key = Object.keys(user).find((k) => user[k] === username);
        if (key) {
          currentState.sessionData.overallStats.globalScore = leaderboard.score[key];
        }
      }
    }
    setProgress(5);
    if (onFinished) onFinished(currentState);
  }, [reportType]);

  // const colors = ['success', 'info', 'warning', 'danger'];
  const colors = ['danger', 'warning', 'info', 'success'];
  return (
    <div className="w-100 d-flex">
      <Col
        sm={{
          offset: 1,
          size: 10,
        }}
      >
        <Progress multi>
          {d3
            .range(totalSteps)
            .map(
              (i) =>
                progress >= i && (
                  <Progress
                    bar
                    key={i}
                    animated={progress < totalSteps}
                    striped={progress >= totalSteps}
                    color={colors[i % 4]}
                    value={parseInt(100 / totalSteps, 10)}
                  />
                )
            )}
        </Progress>
      </Col>
    </div>
  );
}

ProcessingProgress.propTypes = {
  data: PropTypes.object,
  onFinished: PropTypes.func,
  reportType: PropTypes.string,
};

export function DataDrivenTennisReport() {
  return <TennisReport reportType="dataDriven" />;
}

export function AdvancedTennisReport() {
  return <TennisReport reportType="advanced" />;
}
