import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import LoadingStateComponent from '../../../components/LoadingStateComponent';
import { cuematePOST, useAsyncV2 } from '../../../libs/dataAccess';

async function verifyToken(token) {
  const response = await cuematePOST(`/users/verify-email?token=${token}`);
  // if the response include the words invalid or expired in the response message, throw an error

  const msg = response.message ? response.message.toLowerCase() : '';
  if (msg.includes('invalid') || msg.includes('expired')) {
    throw new Error(response.message);
  }
}

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const { error, loading } = useAsyncV2(verifyToken, [searchParams.get('token')]);

  if (loading) {
    return <LoadingStateComponent />;
  }
  if (error) {
    return (
      <Container className="padding-top-50 padding-bottom-100 animated fadeIn">
        <p className="fs-3">Invalid or expired token.</p>
      </Container>
    );
  }

  return (
    <Container className="padding-top-50 padding-bottom-100 animated fadeIn">
      <p className="fs-3">
        Your email address has been successfully updated. All future communications will be sent to
        your new address.
      </p>
    </Container>
  );
}
