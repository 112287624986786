/* eslint no-undef: "error" */
/* eslint-env browser */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import {
  Collapse,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap';
import classnames from 'classnames';
import Logo2 from './images/landingpage/Cue_Mate_Tennis_Logo.svg';
import './App.css';

export const UserGroup = {
  GroupName: 'BETA_WEB_REPORTS',
  CoachGroupName: 'CUEMATE_COACH',
  ADVANCED_REPORTS: 'BETA_WEB_REPORTS_ADVANCED',
};

export default class Header extends Component {
  static shouldRenderAuthenticatedHeaderBasedOnPath() {
    const { location } = window;
    return (
      location.pathname.includes('login') ||
      location.pathname.includes('password-reset') ||
      location.pathname.includes('download-app') ||
      location.pathname.includes('privacy') ||
      location.pathname.includes('verify-email')
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      isCueMateDropdownMenuOpen: false,
      isDropdownDownMenuOpen: false,
      isReportsDropdownMenuOpen: false,
      isIosDropdownMenuOpen: false,
      isWebDropdownMenuOpen: false,
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
    this.toggleDropdownMenu = this.toggleDropdownMenu.bind(this);
    this.toggleDropdownAndMainMenu = this.toggleDropdownAndMainMenu.bind(this);
    this.toggleUserDropdownMenu = this.toggleUserDropdownMenu.bind(this);
    this.toggleCueMateDropdownMenu = this.toggleCueMateDropdownMenu.bind(this);
    this.toggleIosDropdownMenu = this.toggleIosDropdownMenu.bind(this);
    this.toggleWebDropdownMenu = this.toggleWebDropdownMenu.bind(this);
    this.toggleReportsDropdownMenu = this.toggleReportsDropdownMenu.bind(this);
    this.openReportsDropdownMenu = this.openReportsDropdownMenu.bind(this);
    this.closeReportsDropdownMenu = this.closeReportsDropdownMenu.bind(this);
  }

  toggleDropdownMenu() {
    const { isDropdownDownMenuOpen } = this.state;
    this.setState({
      isDropdownDownMenuOpen: !isDropdownDownMenuOpen,
    });
  }

  toggleReportsDropdownMenu() {
    const { isReportsDropdownMenuOpen } = this.state;
    this.setState({
      isReportsDropdownMenuOpen: !isReportsDropdownMenuOpen,
    });
  }

  openReportsDropdownMenu() {
    this.setState({
      isReportsDropdownMenuOpen: true,
    });
  }

  closeReportsDropdownMenu() {
    this.setState({
      isReportsDropdownMenuOpen: false,
    });
  }

  toggleUserDropdownMenu() {
    const { isUserDropdownMenuOpen } = this.state;
    this.setState({
      isUserDropdownMenuOpen: !isUserDropdownMenuOpen,
    });
  }

  toggleIosDropdownMenu() {
    const { isIosDropdownMenuOpen } = this.state;
    this.setState({
      isIosDropdownMenuOpen: !isIosDropdownMenuOpen,
    });
  }

  toggleWebDropdownMenu() {
    const { isWebDropdownMenuOpen } = this.state;
    this.setState({
      isWebDropdownMenuOpen: !isWebDropdownMenuOpen,
    });
  }

  toggleCueMateDropdownMenu() {
    const { isCueMateDropdownMenuOpen } = this.state;
    this.setState({
      isCueMateDropdownMenuOpen: !isCueMateDropdownMenuOpen,
    });
  }

  toggle() {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  toggleDropdownAndMainMenu() {
    const {
      isCueMateDropdownMenuOpen,
      isDropdownDownMenuOpen,
      isOpen,
      isUserDropdownMenuOpen,
      isReportsDropdownMenuOpen,
      isWebDropdownMenuOpen,
    } = this.state;
    if (isCueMateDropdownMenuOpen) {
      this.toggleCueMateDropdownMenu();
    }
    if (isDropdownDownMenuOpen) {
      this.toggleDropdownMenu();
    }

    if (isOpen) {
      this.toggle();
    }
    if (isUserDropdownMenuOpen) {
      this.toggleUserDropdownMenu();
    }
    if (isReportsDropdownMenuOpen) {
      this.toggleReportsDropdownMenu();
    }
    if (isWebDropdownMenuOpen) {
      this.toggleWebDropdownMenu();
    }
  }

  renderUngatedOptions() {
    return (
      <Nav className="ms-auto" navbar>
        <NavItem>
          <Link
            to="/login"
            className="nav-link font-VR-DemiBold text-white"
            onClick={this.toggleDropdownAndMainMenu}
          >
            LOGIN
          </Link>
        </NavItem>
      </Nav>
    );
  }

  renderReportsOption() {
    // let reportsMenuOptionClass = classnames('nav-link', { active: this.props.location.pathname.includes('/home/my-reports') || this.state.isReportsDropdownMenuOpen });

    const { isDataDrivenUser, userGroups } = this.props;

    return (
      <Nav>
        <NavLink
          to="/dashboard/basic"
          className="dropdown-item"
          onClick={this.toggleDropdownAndMainMenu}
        >
          Basic
        </NavLink>
        {isDataDrivenUser && (
          <>
            {(userGroups.includes(UserGroup.ADVANCED_REPORTS) ||
              userGroups.includes(UserGroup.GroupName)) && (
              <NavLink
                to="/dashboard/advanced"
                className="dropdown-item"
                onClick={this.toggleDropdownAndMainMenu}
              >
                Advanced
              </NavLink>
            )}
            {userGroups.includes(UserGroup.GroupName) && (
              <>
                <NavLink
                  to="/dashboard/experiment"
                  className="dropdown-item"
                  onClick={this.toggleDropdownAndMainMenu}
                >
                  Experiment
                </NavLink>
                <NavLink
                  to="/dashboard/computer-vision"
                  className="dropdown-item"
                  onClick={this.toggleDropdownAndMainMenu}
                >
                  Computer Vision
                </NavLink>
              </>
            )}
          </>
        )}
      </Nav>
    );
  }

  renderGatedOptions() {
    const { username, handleLogout } = this.props;
    const { isUserDropdownMenuOpen, isWebDropdownMenuOpen } = this.state;
    const { pathname } = window.location;
    const overviewMenuOptionClass = classnames('nav-link', 'text-white', {
      active: pathname === '/overview',
    });
    /*
    const backgroundClass = classnames('nav-link', 'text-white', {
      active: pathname.includes('/background'),
    });
    const cuemate101Class = classnames('nav-link', 'text-white', {
      active: pathname.includes('/cuemate101'),
    });
    const keyConceptsClass = classnames('nav-link', 'text-white', {
      active: pathname.includes('/key-concepts'),
    });
    const iosClass = classnames('nav-link', 'text-white', {
      active: pathname.includes('/cuemate-ios') || pathname.includes('/cuemate-web'),
    });
    */
    const webClass = classnames('nav-link', 'text-white', {
      active: pathname.includes('/dashboard'),
    });
    const userMenuOptionClass = classnames('nav-link', 'text-white', {
      active: pathname.includes('my-'),
    });

    const overview = (
      <NavItem>
        <Link
          to="/overview"
          className={overviewMenuOptionClass}
          onClick={this.toggleDropdownAndMainMenu}
        >
          Overview
        </Link>
      </NavItem>
    );

    /*     
    const background = (
      <NavItem>
        <Link to="/background" className={backgroundClass} onClick={this.toggleDropdownAndMainMenu}>
          Background
        </Link>
      </NavItem>
    ); 
    */

    /*
    const cuemate101 = (
      <NavItem>
        <Link to="/cuemate101" className={cuemate101Class} onClick={this.toggleDropdownAndMainMenu}>
          CueMate 101
        </Link>
      </NavItem>
    );
    */

    /*
    const keyConcepts = (
      <NavItem>
        <Link
          to="/key-concepts"
          className={keyConceptsClass}
          onClick={this.toggleDropdownAndMainMenu}
        >
          Key Concepts
        </Link>
      </NavItem>
    );
    */

    /*
    const iosDropDown = (
      <Dropdown nav inNavbar isOpen={isIosDropdownMenuOpen} toggle={this.toggleIosDropdownMenu}>
        <DropdownToggle caret nav className={iosClass}>
          App Features
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem header>iOS Features</DropdownItem>
          <Nav>
            <NavLink
              to="/cuemate-ios/"
              className="dropdown-item"
              onClick={this.toggleDropdownAndMainMenu}
            >
              Screens
            </NavLink>
            <NavLink
              to="/cuemate-ios/metrics"
              className="dropdown-item"
              onClick={this.toggleDropdownAndMainMenu}
            >
              Metrics
            </NavLink>
            <NavLink
              to="/cuemate-ios/training"
              className="dropdown-item"
              onClick={this.toggleDropdownAndMainMenu}
            >
              Training
            </NavLink>
            <NavLink
              to="/cuemate-ios/social"
              className="dropdown-item"
              onClick={this.toggleDropdownAndMainMenu}
            >
              Social
            </NavLink>
          </Nav>
          <DropdownItem divider />
          <DropdownItem header>Web Features</DropdownItem>
          <NavLink
            to="/cuemate-web"
            className="dropdown-item"
            onClick={this.toggleDropdownAndMainMenu}
          >
            Web
          </NavLink>
        </DropdownMenu>
      </Dropdown>
    );
    */

    const webDropDown = (
      <Dropdown nav inNavbar isOpen={isWebDropdownMenuOpen} toggle={this.toggleWebDropdownMenu}>
        <DropdownToggle caret nav className={webClass}>
          Web Reports
        </DropdownToggle>
        <DropdownMenu end>{this.renderReportsOption()}</DropdownMenu>
      </Dropdown>
    );

    const userDropDown = (
      <Dropdown nav inNavbar isOpen={isUserDropdownMenuOpen} toggle={this.toggleUserDropdownMenu}>
        <DropdownToggle caret nav className={userMenuOptionClass}>
          {username}
        </DropdownToggle>
        <DropdownMenu end flip>
          <Link to="/my-account" className="dropdown-item" onClick={this.toggleDropdownAndMainMenu}>
            My Account
          </Link>
          <Link
            to="/my-settings"
            className="dropdown-item"
            onClick={this.toggleDropdownAndMainMenu}
          >
            My Settings
          </Link>
          <Link to="/my-gear" className="dropdown-item" onClick={this.toggleDropdownAndMainMenu}>
            My Gear
          </Link>
          <a onClick={handleLogout} className="dropdown-item" href="#logout">
            Logout
          </a>
        </DropdownMenu>
      </Dropdown>
    );

    return (
      <Nav className="ms-auto text-white" navbar>
        {overview}
        {webDropDown}
        {userDropDown}
      </Nav>
    );
  }

  renderUnauthenticatedHeader() {
    document.body.style.paddingTop = '0px';
    return (
      <Navbar color="faded" dark expand="md" className="overlap navbar-padding-1-5-3-rem">
        <Link to="/" className="navbar-brand mr-0">
          <img alt="CueMate Logo" src={Logo2} className="navbar-logo" />
        </Link>
        {this.renderUngatedOptions()}
      </Navbar>
    );
  }

  renderAuthenticatedHeader() {
    const { isAuthenticated } = this.props;
    const { isOpen } = this.state;
    document.body.style.paddingTop = '100px';
    return (
      <Navbar color="faded" dark expand="md" fixed="top" className="black-bg text-white">
        <Link to="/" className="navbar-brand mr-0">
          <img alt="CueMate Logo" src={Logo2} className="navbar-logo" />
        </Link>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={isOpen} navbar>
          {isAuthenticated ? this.renderGatedOptions() : this.renderUngatedOptions()}
        </Collapse>
      </Navbar>
    );
  }

  render() {
    const { isAuthenticated } = this.props;
    return isAuthenticated || Header.shouldRenderAuthenticatedHeaderBasedOnPath()
      ? this.renderAuthenticatedHeader()
      : this.renderUnauthenticatedHeader();
  }
}

Header.propTypes = {
  username: PropTypes.string,
  handleLogout: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  isDataDrivenUser: PropTypes.bool,
  userGroups: PropTypes.array,
};
