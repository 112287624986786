/* eslint no-undef: "error" */
/* eslint-env browser */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import { AppContext } from './libs/contextLib';
import Routes from './Routes';
import Footer from './containers/ungated/Footer';
import GatedFooter from './containers/gated/GatedFooter';
import FeedbackButton from './containers/gated/feedback/FeedbackButton';
import './App.css';
import Header, { UserGroup } from './AppHeader';
import LoadingStateComponent from './components/LoadingStateComponent';

function App() {
  const nav = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticatedState] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [isDataDrivenUser, setIsDataDriven] = useState(false);
  const [username, setUsername] = useState('');

  const userHasAuthenticated = useCallback(async (authenticated) => {
    if (authenticated) {
      try {
        const currentSession = await Auth.currentSession();
        const usersHasGroups = !!currentSession.accessToken.payload['cognito:groups'];
        let hasAccessToAllReports = false;
        if (
          usersHasGroups &&
          (currentSession.accessToken.payload['cognito:groups'].includes(UserGroup.GroupName) ||
            currentSession.accessToken.payload['cognito:groups'].includes(
              UserGroup.ADVANCED_REPORTS
            ))
        ) {
          hasAccessToAllReports = true;
          setUserGroups(currentSession.accessToken.payload['cognito:groups']);
        }
        await API.post('cuemate-tennis', '/storeIdentityIdSecurely', {
          body: {
            idToken: currentSession.idToken.jwtToken,
            accessToken: currentSession.accessToken.jwtToken,
          },
        });
        if (
          usersHasGroups &&
          currentSession.accessToken.payload['cognito:groups'].includes(UserGroup.CoachGroupName)
        ) {
          await API.get('cuemate-tennis-admin', '/permissions', {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            queryStringParameters: {
              access_token: currentSession.accessToken.jwtToken,
            },
          });
        }

        const { username: currentUsername } = currentSession.accessToken.payload;
        window.heap.identify(currentUsername);
        setIsDataDriven(hasAccessToAllReports);
        setUsername(currentUsername);

        if (
          !currentSession.idToken.payload['custom:hasComplSettings'] ||
          currentSession.idToken.payload['custom:hasComplSettings'] === '0'
        ) {
          nav('/my-settings?goback=true');
        }
      } catch (error) {
        console.error('Auth error:- ', error);
      }
    }
    userHasAuthenticatedState(authenticated);
    setIsAuthenticating(false);
  }, []);

  async function onLoad() {
    try {
      await Auth.currentAuthenticatedUser({
        bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });

      userHasAuthenticated(true);
    } catch (e) {
      if (!e.includes('No current user') && !e.includes('not authenticated')) {
        // eslint-disable-next-line no-undef
        alert(e);
      }
    }
    setIsAuthenticating(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticated,
      userHasAuthenticated,
    }),
    [isAuthenticated]
  );

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    nav('/login');
  }

  return (
    (isAuthenticating && <LoadingStateComponent loadingText="Authenticating..." />) ||
    (!isAuthenticating && (
      <div className="App">
        <Header
          {...{
            isAuthenticated,
            isDataDrivenUser,
            userGroups,
            handleLogout,
            username,
          }}
        />
        {isAuthenticated && <FeedbackButton />}
        <AppContext.Provider value={value}>
          <Routes />
        </AppContext.Provider>

        {isAuthenticated ? <GatedFooter /> : <Footer />}
      </div>
    ))
  );
}

export default App;
