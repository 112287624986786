import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  LoadingState,
  trainingConfigurationV2,
  StatefulLayout,
  tennisUtilities,
} from 'cuemate-charts';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { cuemateGET, cuematePUT, cuematePOST, useAsync } from '../../../../../libs/dataAccess';
import onError from '../../../../../libs/errorLib';

const { BATCH_SIZE, convertGoal, determineGoalStatus, METRICS } = tennisUtilities;

const objToQueryString = (obj) => {
  const queryString = new URLSearchParams(obj).toString();
  return queryString;
};

const parseGoalFromDB = (trainingGoal) => {
  const {
    id,
    attainCounter,
    attributeName,
    batches,
    batchSize,
    className,
    goal,
    goalProgress,
    isPinned,
    stageRaw,
    start,
    statusRaw,
    strokeCounter,
    created,
    createdBy,
    modified,
    goalSubType,
    secondaryGoals,
  } = trainingGoal;

  return {
    id,
    _strokeCounter: strokeCounter,
    attainCounter,
    attributeName,
    batchSize,
    batches,
    className,
    goal,
    goalProgress: goalProgress || 0,
    ...(isPinned && { isPinned }),
    stageRaw,
    start,
    statusRaw,
    createdOn: d3.timeFormat('%Y-%m-%dT%H:%M:%S.%LZ')(new Date(created)),
    created,
    ...(createdBy && { createdBy }),
    goalSubType,
    secondaryGoals,
    modified,
  };
};

const CUEMATE_CREATOR = 'cuemate';
const CUEMATE_CORE_CREATOR = 'cuemate_core';

const listTGoals = async () => {
  const currentSession = await Auth.currentSession();
  const responseAllGoals = await cuemateGET('/goal', currentSession.accessToken.jwtToken);
  const allGoals = !responseAllGoals ? [] : responseAllGoals.map(parseGoalFromDB);

  // Separate goals based on `createdBy` field
  const cMTrainingGoals = allGoals.filter((goal) => goal.createdBy === CUEMATE_CREATOR);
  const cMCoreTrainingGoals = allGoals
    .filter((goal) => goal.createdBy === CUEMATE_CORE_CREATOR)
    .map((goal) => ({
      ...goal,
    }));
  return {
    cMTrainingGoals,
    cMCoreTrainingGoals,
  };
};

const listActivityGoals = async () => {
  const currentSession = await Auth.currentSession();
  const response = await cuemateGET('/users/goals/activity', currentSession.accessToken.jwtToken);
  const result = !response ? [] : response;
  return result;
};

const updateGoal = async (goal) => {
  const currentSession = await Auth.currentSession();
  // data is an array of goals - only send the updated fields with the goal ID
  const data = [{ ...goal, modified: new Date().getTime() }];
  const response = await cuematePUT('/goal', currentSession.accessToken.jwtToken, data);
  return Array.isArray(response) ? response : [];
  // response is an array of updated goals with their ID
};

const addGoal = async (goal) => {
  const currentSession = await Auth.currentSession();
  // data is an array of goals - not all the fields are required
  const response = await cuematePOST('/goal', currentSession.accessToken.jwtToken, [goal]);
  return Array.isArray(response) ? response : [];
  // response is an array of inserted goals with their ID
};

const getTrainingTips = async (obj) => {
  const currentSession = await Auth.currentSession();
  const queryString = objToQueryString(obj);
  const response = await cuemateGET(
    `/user/training/tips?${queryString}`,
    currentSession.accessToken.jwtToken
  );
  const result = !response ? [] : response;
  return result;
};

const getActivityProfiles = async () => {
  try {
    const currentSession = await Auth.currentSession();
    const response = await cuemateGET(
      '/users/activity/profiles/status',
      currentSession.accessToken.jwtToken
    );
    if (response.code && response.message) {
      throw new Error(`Failed to get activity profile status: ${response.message}`);
    }
    const result = !response ? {} : response;
    return result;
  } catch (error) {
    onError(error, false);
  }
  return {};
};

const GROUP_TYPE = 'refRanges';
const GROUP_STATUS = 'active';

const getGroupActivityProfiles = async (groupId, token) => {
  try {
    const response = await cuemateGET(`/users/groups/${groupId}/activity/profiles/status`, token);
    if (response.code && response.message) {
      throw new Error(
        `Failed to get user group profiles for group ${groupId}: ${response.message}`
      );
    }
    return response || [];
  } catch (error) {
    onError(error, false);
    return [];
  }
};

const getUsersGroupActivityProfiles = async () => {
  try {
    const currentSession = await Auth.currentSession();
    const { jwtToken: token } = currentSession.accessToken; // Destructuring
    const response = await cuemateGET(
      `/users/social/groups?groupType=${GROUP_TYPE}&status=${GROUP_STATUS}`,
      token
    );
    if (response.code && response.message) {
      throw new Error(`Failed to get user groups: ${response.message}`);
    }
    const userGroups = response || [];

    const groupsWithProfiles = await Promise.all(
      userGroups.map(async (group) => {
        const profiles = await getGroupActivityProfiles(group.id, token); // Assuming group.id exists
        return { ...profiles, ...group };
      })
    );
    return groupsWithProfiles;
  } catch (error) {
    onError(error, false);
    return [];
  }
};

/* Training goal values are converted into the following default units for storage
// into the database */
const DEFAULT_UNITS = ['mm', 'm', 'm/s', 'm/s/J', 'rad/s', 'rpm/J', 'rad'];

export default function TrainingContent({
  username,
  elements,
  classData,
  rating,
  reportType,
  units,
}) {
  const [loading, error, data, setData] = useAsync(listTGoals, []);
  const [loadingActivity, errorActivity, activityData] = useAsync(listActivityGoals, []);
  const [loadingActivityProfile, errorActivityProfile, activityProfile] = useAsync(
    getActivityProfiles,
    []
  );
  const [loadingGroupActivityProfiles, errorGroupActivityProfiles, groupActivityProfiles] =
    useAsync(getUsersGroupActivityProfiles, []);

  const [activeGoalId, setGoalId] = useState(null);
  const [add, setAdd] = useState(null);

  if (loading || loadingActivity || loadingActivityProfile || loadingGroupActivityProfiles)
    return <LoadingState />;
  if (error || errorActivity || errorActivityProfile || errorGroupActivityProfiles)
    // eslint-disable-next-line no-undef
    alert(error);

  const allMetrics = METRICS.map((d) => d.value).flat();

  // Sort goals in reverse chronological order by modified time
  const trainingData = [...data.cMTrainingGoals, ...data.cMCoreTrainingGoals].flatMap((g) => {
    // Process the primary goal
    const goal = {
      ...convertGoal(g, units),
      batchSize: g.batchSize ? parseInt(g.batchSize, 10) : BATCH_SIZE,
    };

    const statusRaw =
      goal.statusRaw === 'archive' ? 'archive' : determineGoalStatus(goal, elements);
    const statusDate =
      goal.batches.length > 0 ? goal.batches[goal.batches.length - 1].lastDatetime : new Date();
    const selectedClass = classData.find((d) => d.name === g.className);
    let stageRaw = 'empty';
    if (selectedClass) {
      stageRaw = selectedClass.skillStatus;
    }

    const primaryGoal = {
      ...goal,
      statusRaw,
      stageRaw,
      statusDate: d3.timeFormat('%Y-%m-%dT%H:%M:%S.%LZ')(new Date(statusDate)),
    };

    // Process the secondary goals if they exist
    const secondaryGoals = g.secondary_goals
      ? g.secondary_goals.map((sg) => convertGoal(sg, units))
      : [];

    // Return both the primary goal and any secondary goals as an array
    return [primaryGoal, ...secondaryGoals];
  });
  const toggleAddition = async (goal) => {
    // Check if there is an existing training goal for selection
    const id = trainingData.findIndex(
      (d) =>
        d.className === goal.className &&
        d.attributeName === goal.attributeName &&
        d.statusRaw === 'active'
    );
    if (id >= 0) {
      setAdd('warning');
      setGoalId(trainingData[id].id);
    } else {
      // Prepare the variable for the new training goal
      const classElements = elements
        .filter((d) => d.classification === goal.className)
        .slice(-BATCH_SIZE);
      const metric = allMetrics.find((d) => d.name === goal.attributeName);
      const mean = d3.mean(classElements, (d) => Math.abs(d[goal.attributeName]));
      const std = d3.deviation(classElements, (d) => Math.abs(d[goal.attributeName]));
      let goalValue = goal.value ? goal.value : mean + std / 4;
      if (metric.max && metric.max < goalValue) {
        goalValue = metric.max;
      }
      const date = d3.timeFormat('%Y-%m-%dT%H:%M:%S.%LZ')(Date.now());
      const dateInt = new Date().getTime();
      const newGoal = {
        user: username,
        strokeCounter: 0,
        alpha: 0,
        attainCounter: 0,
        attributeName: goal.attributeName,
        batches: [],
        batchSize: BATCH_SIZE,
        className: goal.className,
        cueOn: false,
        goal: goalValue,
        lastActive: classElements[classElements.length - 1].datetime,
        lastValue: 0,
        stageRaw: classData.find((d) => d.name === goal.className).skillStatus,
        start: mean,
        trend: 0,
        statusDate: date,
        statusRaw: 'active',
        weightedAvg: 0,
        created: dateInt,
        modified: dateInt,
      };

      const goalToAdd = convertGoal(newGoal, DEFAULT_UNITS, 'to');
      const response = await addGoal(goalToAdd);

      // Update the state
      if (response.length > 0) {
        // Replace existing goal
        const newData = {
          cMTrainingGoals: [...data.cMTrainingGoals, parseGoalFromDB(response[0])],
        };
        setData(newData);
        setAdd('success');
        setGoalId(response[0].id);
        // Hides the 'Goal Added' alert after 2 seconds
        setTimeout(() => {
          setAdd(null);
        }, 2000);
      }
    }
  };

  const toggleUpdate = async (goal) => {
    const updatedGoal = convertGoal(goal, DEFAULT_UNITS, 'to');
    const response = await updateGoal({
      id: updatedGoal.id,
      className: updatedGoal.className,
      goal: updatedGoal.goal,
    });
    if (response.length > 0) {
      // Replace existing goal
      const newData = {
        // eslint-disable-next-line no-shadow
        cMTrainingGoals: data.cMTrainingGoals.map((goal) =>
          goal.id === response[0].id ? parseGoalFromDB(response[0]) : goal
        ),
      };
      setData(newData);
      setGoalId(response[0].id);
    }
  };

  // TODO: Rename to toggleArchive. Create function for deleting goal.
  const toggleDelete = async (goalId) => {
    const goal = trainingData.find((g) => g.id === goalId);
    const response = await updateGoal({
      id: goalId,
      className: goal.className,
      statusRaw: 'archive',
    });
    if (response.length > 0) {
      // Replace existing goal
      const newData = {
        // eslint-disable-next-line no-shadow
        cMTrainingGoals: data.cMTrainingGoals.map((goal) =>
          goal.id === response[0].id ? parseGoalFromDB(response[0]) : goal
        ),
      };
      setData(newData);
      setGoalId(response[0].id);
    }
  };

  const toggleTransition = (context) => {
    setGoalId(context.split(',')[1]);
  };

  return (
    <StatefulLayout
      viewConfig={trainingConfigurationV2}
      data={{
        activeGoalId,
        activityData,
        add,
        classData,
        elements,
        getTrainingTips,
        groupActivityProfiles,
        playerRating: rating,
        reportType,
        toggleAddition,
        toggleDelete,
        toggleTransition,
        toggleUpdate,
        activityProfile,
        trainingData,
        username,
      }}
    />
  );
}

TrainingContent.propTypes = {
  username: PropTypes.string,
  elements: PropTypes.array,
  classData: PropTypes.array,
  rating: PropTypes.object,
  reportType: PropTypes.string,
  units: PropTypes.array,
};
